/* eslint-disable import/extensions */
// @ts-ignore
import { defaults } from './defaultConfigs';

export const Localization = {
  ...defaults,
  assignedCs: 'Assigned FC',
  csId: 'FC ID',
  currencySymbol: '₹',
  currencyAbbrev: 'INR',
  branch: 'Locations',
  accountBranch: 'Branch',
  branches: 'Branches',
  collectionSpecialist: 'Field Collectors',
  principalPayment: 'Outstanding Payment*',
  showPenaltyPayment: true,
  penaltyPayment: 'Charges Received',
  csLeaderBoard: 'CS Leaderboard',
  branchLeaderBoard: 'Branch Leaderboard',
  collectionsBarChartTitle: 'Field Collections',
  accountsBarChartTitle: 'Field Accounts',
  collectionSpecialistPerformanceTitle: 'Field Collectors Performance',
  branchListingHeader: 'Locations',
  branchListingBreadcrumb: 'Locations',
  csListingHeader: 'Field Collectors',
  csListingBreadcrumb: 'Field Collectors',
  csDetailsBreadcrumb: 'Specialist',
  logoFilename: 'TVSDigital_logo.svg',
  clientName: 'TVS Digital',
  navbarBackgroundColor: '#34276E',
  showManuals: true,
  showAIManuals: false,
  showAPK: false,
  csManualLink:
    'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Generic_FieldCollector_UserManual.pdf',
  cashierManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Generic_Cashier_UserManual.pdf',
  managerManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Generic_Manager_UserManual.pdf',
  aiManualLink: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/artha/manuals/Wheeltek_AI_Itinerary.pdf',
  apkLink: process.env.APK_URL || '',
  eulaTextUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.txt',
  eulaVersionUrl: 'https://tvsd-assets.s3-ap-southeast-1.amazonaws.com/eula/EULA_webapp.json',
  privacyStatementUrl:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.txt',
  privacyStatementVersion:
    'https://tvsd-assets.s3.ap-southeast-1.amazonaws.com/privacy-statement/mobile_application_privacy_statement.json',
  zipcodeLength: 6,
  zipcodeValidationMsg: 'India zipcode should have 6 characters. Ex: 744202, 683544',
  country: 'India',
  countryAbbrev: 'IN',
  transactionListingHeader: 'Transaction Listing',
  transactionListingBreadcrumb: 'Transaction Listing',
  activateTransactionListing: true,
  activateCreditDebitMemo: true,
  activatePPD: false,
  currencyLocale: 'en-IN',
  hiddenPenaltyField: true,
  csReportDownload: false,
  cashierReportDownload: true,
  appId: '1:834347563222:web:fbd4fc0f3cdb919c9ab177',
  measurementId: 'G-MF77K6LBTS',
  apiKey: 'AIzaSyCVLsRH42MqY7p3KOSqpKq70AnKa7-KsBE',
  showTranslation: false,
  recoveredAccountsLabel: 'RECOVERED ACCOUNTS',
  noOfPaymentsLabel: 'NO. OF RECORDS',
  showCashierPage: false,
  displayClientCode: false,
  metricsToDisplay: ['mtd-collection-efficiency', 'account-recovery-rate', 'average-visits-per-day'],
  cspStatsToHide: ['total_skipped_visits', 'itinerary_non_compliance', 'visit_per_day'],
  showSummaryReportDownload: true,
  collectionSpecialistItinerary: 'Field Collector Itinerary',
  changePasswordUrl: 'https://signonuat.tvscredit.com/login.aspx',
  ipAddressUrlOne: 'https://api.ipify.org/?format=json',
  ipAddressUrlTwo: 'https://api.bigdatacloud.net/data/client-ip',
  employeeID: true,
};
